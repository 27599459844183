import styled from "styled-components";

const TopBarWrapper = styled.div`
    text-align: left;
    height: 81px;
    border-bottom: 1px solid #EEEEEE;
    padding: 30px 40px;
    width: calc(100vw - ${props => props.sideBarCollapsed ? "90px" : "260px"});
    font-size: 18px;
    pointer-events: none;
    position: absolute;
    right: 0;
    background: #fff;
    z-index: 99;
    
    img {
        width: 20px;
        float: right;
    }

    &.hide {
        opacity: 0;
    }
`;

export default TopBarWrapper;