import { useEffect } from "react";

function About(props) {
  useEffect(() => {
    props.setCurrentProject({});
  }, []);
  return (
    <div
      style={{
        padding: "150px 50px 0px",
        fontSize: 22,
        letterSpacing: 2,
      }}
    >
      <p>
        COAScape-AI v0.1 is a tool for COA researchers aimed to expedite the
        development of patient-focused conceptual frameworks. This software
        scans literature searches downloaded from OVID by the researcher and
        produces AI generated eligibility assessments of study abstracts,
        accompanying inclusion/exclusion flowchart, and a preliminary conceptual
        framework of symptoms and impacts for the target indication.
      </p>

      <p>
        COAScape-AI v0.1 requires a downloaded OVID search result file. OVID
        files should contain the following codes for COAScape-AI v0.1 to work
        optimally: ORN, TI, AB, AU, PT, DB, JN, VO, IP and PG. Once you have
        downloaded this file from OVID, upload this downloaded OVID search
        result file in the Projects tab.
      </p>
    </div>
  );
}
export default About;
