import Logo from "../../images/logo.png"
import { LoginForm } from "../LoginPage/style"

function SignUp() {
  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center"
      }}
    >
      <LoginForm
        style={{
          padding: 50,
          fontSize: 22, 
          background: "#fff"
        }}
      >
        <img src={Logo} style={{ marginBottom: 50, marginTop: 70}} />
        <p>Please contact <a href="mailto:angie.viada@clinoutsolutions.com">Angie Viada</a> to sign up to  this service.</p>
      </LoginForm>
    </div>
  )
}
export default SignUp
