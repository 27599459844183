import React from "react";
// import { MarkerType } from "reactflow";

const workStream = localStorage.getItem("workStream") || "A";

export const nodes = ({
  duplicate,
  eligible,
  outcome,
  population,
  study_design,
  total,
}) => {
  return [
    {
      id: "1",
      type: "input",
      data: {
        label: "OVID Search (N=" + total + ")",
      },
      position: { x: 150, y: 0 },
      style: {
        width: 200,
      },
    },
    {
      id: "2",
      style: {
        width: 300,
        textAlign: "left",
      },
      data: {
        label:
          workStream === "A" ? (
            <div>
              <i>Excluded:</i>
              <br />- Populations (n={population}) <br />
              - Study Type (n={study_design}) <br />
              - Outcomes (n={outcome}) <br />
              - Duplicates (n={duplicate})
              <br />
            </div>
          ) : (
            <div>
              <i>Excluded:</i>
              <br />
              - Not in condition of interest (n=380) <br />
              - Not related to PRO/QoL (n=450) <br />
              - Not study design of interest (n=370) <br />
            </div>
          ),
      },
      position: { x: 400, y: 50 },
      targetPosition: "left",
    },
    // {
    //   id: "3",
    //   data: {
    //     label:
    //       workStream === "A"
    //         ? " Total Unique Concepts identified (n=22)"
    //         : " Total Unique COA measurements identified (n=22)",
    //   },
    //   position: { x: 400, y: 220 },
    //   targetPosition: "left",
    //   style: {
    //     width: 300,
    //     textAlign: "left",
    //   },
    // },
    {
      id: "4",
      position: { x: 150, y: 200 },
      data: {
        label: "Total publications (n=" + eligible + ")",
      },
      sourcePosition: "right",
      style: {
        width: 200,
      },
    },
  ];
};

export const edges = [
  { id: "e1-4", source: "1", target: "4" },
  { id: "e1-2", source: "1", target: "2", type: "step" },
  { id: "e3-4", source: "4", target: "3" },
];
