import styled from "styled-components";
import menuItemIcon from "../../images/menuItem.svg";
import menuItemActiveIcon from "../../images/menuItem-active.svg";

export const MenuToggle = styled.div`
  cursor: pointer;

  img.toggle {
    width: 42px;
    display: inline-block;
    vertical-align: top;
    margin-left: -5px;
    margin-top: -6px;
  }

  .arrow {
    position: absolute;
    border: 1px solid #eee;
    right: -14px;
    padding: 8px 10px;
    border-radius: 50%;
    background: #fff;
  }

  h1 {
    font-size: 18px;
    display: inline-block;
    line-height: 12px;
    color: #0e37a4;
    margin-top: 6px;
    margin-left: 20px;
    font-weight: 600;

    span {
      font-size: 8px;
      color: #515151;
      font-weight: 400;
    }
  }
`;

export const MenuItem = styled.div`
  padding: 20px 0;
  color: ${(props) => (props.active ? "#2359EE" : "#515151")};
  font-size: 16px;
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    width: 30px;
    height: 30px;
    vertical-align: middle;
    margin-right: 17px;
  }

  .menuIcon {
    display: inline-block;
    width: 32px;
    height: 32px;
    vertical-align: middle;
    margin-right: 17px;

    &.projectIcon {
      img {
        width: 25px;
        height: 25px;
      }
    }
  }

  span {
    vertical-align: middle;
    position: relative;
    top: -1px;
  }
  div {
    width: 150px;
    span {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  .overlay {
    display: block;
    background: #f8f9fc;
    width: calc(100% + 60px);
    height: 60px;
    position: absolute;
    left: -30px;
    top: 5px;
    z-index: -1;
  }

  // &::before {
  //     content: " ";
  //     display: inline-block;
  //     background-image: url("${(props) =>
    props.active ? menuItemActiveIcon : menuItemIcon}");
  //     background-size: cover;
  //     width: 21px;
  //     height: 21px;
  //     vertical-align: bottom;
  //     margin-right: 17px;
  // }

  &::after {
    content: "";
    border-left: 4px solid
      ${(props) => (props.active ? "#2359EE" : "transparent")};
    display: inline-block;
    width: 4px;
    height: 40px;
    position: absolute;
    left: -30px;
    top: 22px;
    transition: all 0.15s ease;
  }

  &.dashboardItem {
    &::after {
      top: 15px;
    }
  }

  .menuItemUuid {
    color: #aaa;
    top: -5px;
    font-size: 14px;
  }

  .ant-menu-submenu.ant-menu-submenu-inline {
    border-bottom: 1px solid #efefef;
    padding-bottom: 10px;
    padding-top: 10px;
  }

  .disabled {
    color: red;
  }
`;

export const SubMenuItem = styled.div`
  width: 100%;
  color: #515151;
  font-size: 14px;
  padding: 10px 30px;
  letter-spacing: 0.01em;

  a {
    color: #515151;
  }

  &.active {
    text-decoration: underline;
  }
`;

export const SubMenu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f8f9fc;
  width: ${(props) => (props.isVisible ? "180px" : "0px")};
  opacity: ${(props) => (props.isVisible ? "1" : "0")};
  height: 100vh;
  position: absolute;
  left: ${(props) => (props.sideBarCollapsed ? "90px" : "90px")};
  margin-top: -30px;
  z-index: 1;
  transition: all 0.25s ease;
  overflow: hidden;
`;

export const ProfileLink = styled.div`
  margin-bottom: -15px;
  display: flex;
  justify-content: space-around;
  color: #000;

  img {
    margin-right: 12px;
    width: 30px;
  }
`;

export const Seperator = styled.hr`
  width: calc(100% + 60px);
  background: #eee;
  height: 1px;
  border: none;
  margin-left: -30px;
`;

const SideBar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: ${(props) => (props.expanded ? "260px" : "90px")};
  height: 100vh;
  border-right: 1px solid #eeeeee;
  text-align: left;
  padding: 30px;
  float: left;
  transition: all 0.25s ease;
  white-space: nowrap;
  position: fixed;
  background: #fff;
  z-index: 1;

  .menu-items {
    margin-top: 60px;
    height: calc(100vh - 240px);
    width: ${(props) => (props.expanded ? "260px" : "90px")};
    overflow: hidden;
    padding-left: 30px;
    margin-left: -30px;
  }

  .items {
    padding-left: 20px;
    height: calc(100vh - 380px);
    color: red;
    overflow: auto;
  }

  .items::-webkit-scrollbar {
    width: 3px;
    background: #828ba0;
  }
  .items::-webkit-scrollbar-track {
    background: #fff;
  }
  .items::-webkit-scrollbar-thumb {
    background: #828ba0;
  }

  .footer {
    margin-top: auto;
    height: 135px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    hr {
      margin-bottom: 0;
    }
  }

  ${MenuItem} {
    color: ${(props) =>
      props.expanded && !props.subMenuVisible ? "inital" : "transparent"};
    transition: color 0.25s ease;

    .menuItemUuid {
      color: ${(props) =>
        props.expanded && !props.subMenuVisible ? "#aaa" : "transparent"};
    }
  }

  ${MenuToggle} {
    h1,
    span {
      color: ${(props) =>
        props.expanded && !props.subMenuVisible ? "inital" : "transparent"};
      transition: color 0.25s ease;
    }
  }

  ${ProfileLink} {
    color: ${(props) =>
      props.expanded && !props.subMenuVisible ? "inital" : "transparent"};
  }

  .ant-menu-title-content {
    font-size: 16px;
  }

  .ant-menu {
    margin-left: 0px;
    margin-top: -12px;

    .menuIcon {
      width: 30px;
      height: 37px;
      margin-right: 8px;
    }
  }
  .ant-menu.ant-menu-sub {
    margin-top: 0px;
    background: #fff;
  }

  .ant-menu-item.ant-menu-item-only-child {
    padding-left: 65px !important;
  }

  .ant-menu-submenu-title {
    padding-left: 0px !important;
  }
`;

export default SideBar;
