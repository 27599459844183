import { LoginForm, InputGroup, SubmitButton } from "./style";

import { Link, useHistory } from "react-router-dom";
import { Alert, Button, Form, Input, Tabs } from "antd";
import { loginAction, resetPasswordRequest, sessionCheck } from "../../helpers/apiCall";
import { useState, useEffect } from "react";

import packageJSON from "../../../package.json";

const LoginPage = (props) => {
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState(null);
  const [activeTab, setActiveTab] = useState(1);
  const [email, setEmail] = useState("");
  const history = useHistory();

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    if (userId)
      sessionCheck(userId)
        .then((res) => {
          if (res.status === 200) history.push("/about");
        })
        .catch((e) => {
          console.log({ e });
        });
  }, []);

  const handleSubmit = (values, a, b) => {
    if (values.userName && values.password) {
      setMsg(null);
      login(values.userName, values.password);
    }
  };

  const resetPassword = () => {
    setLoading(true);
    resetPasswordRequest(email).then(res => {
      setLoading(false);
      setMsg({
        message: "Please check your email",
        type: "success"
      })
      console.log('hello : ', {res})
    })
    .catch(e => {
      setLoading(false);
      setMsg({
        message: "Something went wrong while restting your password",
        type: "error"
      })
      console.log('hello : ', {e})
    })
    console.log({email})
  }

  const login = (userName, password) => {
    setLoading(true);

    loginAction(userName, password)
      .then((res) => {
        setLoading(false);
        props.setAppKey(new Date());
        history.push("/about");
      })
      .catch((e) => {
        console.log('herer : ', { e });
        setLoading(false);
        if(e === 429)
          setMsg({
            type: "error",
            message: <span>We've detected unusual activity on your account.  Please <Link to="#">click here </Link>to reset your password.</span>
          });
        else
          setMsg({
            type: "error", 
            message: "Username or  password incorrect"
          });
      });

    return false;
  };

  return (
    <div style={{ borderTop: "1px solid transparent" }}>
      <LoginForm style={{ marginTop: props.popup ? 0 : undefined }}>
        <Tabs
          activeKey={activeTab}
          items={[{
            key: 1,
            children: 
            <>
              <div className="loginContainer">
                <Form onFinish={handleSubmit} name="loginForm" className="loginForm">
                  {msg && (
                    <Alert
                      message={msg.message}
                      type={msg.type}
                      style={{ marginTop: -50, marginBottom: 10 }}
                    />
                  )}
                  <InputGroup>
                    <Form.Item
                      name="userName"
                      label="Username"
                      rules={[
                        { required: true, message: "Please input your username!" },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </InputGroup>
                  <InputGroup>
                    <Form.Item
                      name="password"
                      label="Password"
                      rules={[
                        { required: true, message: "Please input your password!" },
                      ]}
                    >
                      <Input type="password" />
                    </Form.Item>
                  </InputGroup>
                  <SubmitButton>
                    <Link
                      to="/signup"
                      style={{
                        padding: "10px 30px",
                        boxShadow: "none",
                        fontSize: "16px",
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                    >
                      Sign up
                    </Link>

                    <Button htmlType="submit" loading={loading}>
                      Login
                    </Button>
                  </SubmitButton>
                </Form>
              </div>

              {packageJSON.version ? (
                <span style={{ float: "right", marginBottom: -20, marginRight: 20 }}>
                  v{packageJSON.version}
                </span>
              ) : null}
              <span
                style={{
                  clear: "both",
                  display: "block",
                  textAlign: "center",
                  paddingTop: 20,
                }}
              >
                Forgot your password ? <Link to="#" onClick={() => setActiveTab(2)}>Click here to reset.</Link>
              </span>
            </>
          }, {
            key: 2,
            children:
            <div className="loginContainer">
              <div className="loginForm" style={{ display: "flex", alignItems: "center", flexWrap: "wrap"}}>
                {msg && (
                  <Alert
                    message={msg.message}
                    type={msg.type}
                    style={{ marginTop: -60, width: "100%"}}
                  />
                )}
                <h2 style={{
                  borderBottom: "1px solid",
                  paddingBottom: 14,
                  width: "100%",
                  marginBottom: 30
                }}>
                  Reset password
                </h2>
                
                <div style={{ display: "flex", alignItems: "center"}}>
                  <InputGroup style={{ marginBottom: 15}}>
                    <Form.Item
                      name="email"
                      label="Email"
                      rules={[
                        { required: true, message: "Please input your Email!" },
                      ]}
                    >
                      <Input 
                        style={{ width: 400}} 
                        onChange={e => setEmail(e.target.value)}
                        value={email}
                      />
                    </Form.Item>
                  </InputGroup>
                  <SubmitButton>
                    <Button loading={loading} onClick={resetPassword}>
                      Reset
                    </Button>
                  </SubmitButton>
                </div>
                <SubmitButton style={{ marginRight: 0}}>
                  <Button>
                    <Link 
                      to="#" 
                      onClick={() => {setMsg(null);setActiveTab(1)}}
                    >
                      Back to Login
                    </Link>
                  </Button>
                </SubmitButton>
              </div>
            </div>
          }]}
        />
        
      </LoginForm>
    </div>
  );
};

export default LoginPage;
