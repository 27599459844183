import React, { useEffect } from "react";
import TopBarWrapper from "./style";
import { useLocation } from "react-router-dom";

const capitalize = (str) => {
  if (str) {
    const [fl, ...rest] = str.trim();
    return fl?.toUpperCase() + rest.join("") || "";
  }
  return "";
};

const projectName = "xxx";
const workStream = "A";
const TopBarContainer = ({ sideBarCollapsed, currentProject }) => {
  const location = useLocation();

  useEffect(() => {}, [location.pathname]);

  return (
    <TopBarWrapper sideBarCollapsed={sideBarCollapsed}>
      <h2 style={{ marginTop: -10, float: "left" }}>
        {location.pathname === "/dashboard"
          ? "Home"
          : location?.pathname?.split("/")[1] === "user"
          ? localStorage.getItem("email")
          : capitalize(location.pathname.split("/")[1])}
      </h2>
      {projectName && workStream && (
        <span style={{ float: "right" }}>
          {currentProject?.name} | {currentProject?.project_type}
        </span>
      )}
    </TopBarWrapper>
  );
};

export default TopBarContainer;
