import React, { useEffect, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";

import SideBarWrapper, {
  MenuItem,
  MenuToggle,
  ProfileLink,
  Seperator,
} from "./style";

import menuItemIcon from "../../images/menuItem.svg";
import menuItemActiveIcon from "../../images/menuItem-active.svg";
import arrowLeft from "./arrow_1.svg";
import arrowRight from "./arrow_2.svg";
import { Menu, Spin, notification, Popconfirm, Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { deleteProject, getProjects, logoutAction } from "../../helpers/apiCall";
import { WarningOutlined, SettingOutlined } from "@ant-design/icons";

import Logo from "../../images/logo.png";

let interval = null;

const DrawerContainer = (props) => {
  const location = useLocation();
  const history = useHistory();
  const [api, contextHolder] = notification.useNotification();

  const [subMenuVisible, toggleSubMenu] = useState(false);
  const [projects, setProjects] = useState([]);

  const toggleDrawer = () => {
    props.toggleSideBar(!props.sideBarCollapsed);
  };

  const clickEvent = (to, p) => {
    props.setCurrentProject(p);
    history.push(to);
  };

  const refreshProjects = () => {
    getProjects().then((res) => {
      res?.data && setProjects(res?.data);
    });
  };

  const delete_project = (id, projectName) => {
    deleteProject(id)
      .then((res) => {
        api.success({
          message: "Success",
          description: "Project " + projectName + " has been deleted.",
        });
        props.setCurrentProject({});
        history.push("/about");
      })
      .catch((e) => {
        console.log({ e });
        api.error({
          message: "Failed",
          description: "Could not delete project " + projectName,
        });
      });
  };

  const logout = () => {
    logoutAction();
  }

  useEffect(() => {
    refreshProjects();
    interval = setInterval(() => {
      refreshProjects();
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, [location.pathname]);

  const currentProjectId = window.location.pathname.split("/")[2];
  const currentPage = window.location.pathname.split("/")[1];

  return (
    <SideBarWrapper
      expanded={!props.sideBarCollapsed}
      subMenuVisible={subMenuVisible}
    >
      {contextHolder}
      <MenuToggle onClick={toggleDrawer}>
        <img
          src={props.sideBarCollapsed ? arrowRight : arrowLeft}
          className="arrow"
          alt="arrow"
        />
        {/* <img src={logo} alt="logo" className="logo toggle" /> */}
        {!props.sideBarCollapsed ? (
          <img
            src={Logo}
            style={{
              float: "left",
              width: 220,
              marginBottom: -50,
              marginTop: -20,
              marginLeft: -15,
            }}
          />
        ) : (
          <h1>COA</h1>
        )}
      </MenuToggle>
      <div className="menu-items">
        <Link to="/about" key="dashboard">
          <MenuItem
            active={location.pathname === "/about" ? true : false}
            className="dashboardItem"
          >
            <span className={`menuIcon `}>
              <img
                src={`${
                  location.pathname === "/about"
                    ? menuItemActiveIcon
                    : menuItemIcon
                }`}
                alt="icon"
              />
            </span>
            <span>About</span>
          </MenuItem>
        </Link>
        <MenuItem
          active={location.pathname === "/dashboard" ? true : false}
          className="dashboardItem"
        >
          <span className={`menuIcon `}>
            <img src={menuItemIcon} alt="icon" />
          </span>
          <span>
            <Link to="/dashboard">Projects</Link>
          </span>
        </MenuItem>
        <div className="items">
          <MenuItem
            active={location.pathname === "/dashboa" ? true : false}
            className="dashboardItem"
          >
            <Menu
              mode="inline"
              key={currentProjectId}
              defaultOpenKeys={[currentProjectId]}
              defaultSelectedKeys={[currentProjectId + currentPage]}
              items={[
                ...projects.map((p) => ({
                  key: p.id,
                  icon: (
                    <span className={`menuIcon projectIcon`} title={p.name}>
                      <img src={menuItemIcon} />
                    </span>
                  ),

                  label: (
                    <div title={p.name}>
                      <span
                        style={{
                          float: "left",
                          "text-overflow": "ellipsis",
                          width: 85,
                        }}
                      >
                        {p.name}
                      </span>
                      <Popconfirm
                        title="Delete project"
                        description="Are you sure to delete this project?"
                        onConfirm={() => delete_project(p.id, p.name)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <DeleteOutlined
                          style={{
                            float: "right",
                            marginTop: 15,
                            marginRight: 39,
                            zIndex: 9,
                          }}
                          title="delete"
                        />
                      </Popconfirm>
                    </div>
                  ),

                  children: [
                    {
                      key: p.id + "screening",
                      onClick: () => clickEvent(`/screening/${p.id}`, p),
                      label: (
                        <span
                          key="screening"
                        >
                          Screening
                        </span>
                      ),
                    },
                    {
                      key: p.id + "results",
                      onClick: () => p.ai_status == "Success"
                          ? clickEvent(`/results/${p.id}`, p)
                          : null,
                      label: (
                        <span
                          key="result"
                        >
                          Results{" "}
                          {p.ai_status === "In Progress" ? (
                            <Spin
                              style={{ width: 50 }}
                              size="small"
                              spinning={true}
                            />
                          ) : p.ai_status === "Failed" ? (
                            <WarningOutlined />
                          ) : null}
                        </span>
                      ),
                      disabled: p.ai_status !== "Success",
                      title: p.ai_status,
                    },
                  ],
                })),
              ]}
            />
          </MenuItem>
        </div>
      </div>

      <div className="footer">
        <Seperator />
        <Link to={"/user/" + localStorage.getItem("userId")}>
          <ProfileLink>
            <SettingOutlined style={{ fontSize: 25, foat: "left" }} />

            <span
              style={{
                textOverflow: "ellipsis",
                width: 170,
                display: "inline-block",
                overflow: "hidden",
                marginBottom: -4,
                fontSize: 14,
              }}
            >
              {localStorage.getItem("email")}
            </span>
          </ProfileLink>
        </Link>
        
        <Button onClick={logout}>
          Logout
        </Button>
      </div>
    </SideBarWrapper>
  );
};

export default React.memo(DrawerContainer);
