import {
  Upload,
  Modal,
  Input,
  Select,
  Button,
  notification,
  Alert,
} from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { useState } from "react";
import readXlsxFile from "read-excel-file";
import { createProject, uploadProjectFile } from "../../helpers/apiCall";
import ProjectsComponent from "../Projects";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";

const { Dragger } = Upload;
const { Option } = Select;

const UploadPage = (props) => {
  const [workStreamPopup, setWorkStreamPopup] = useState(false);
  const [fileName, setFileName] = useState("");
  const [fileUpload, setFile] = useState(null);
  const [nRows, setNRows] = useState(0);
  const [errMsg, setErrMsg] = useState("");
  const [api, contextHolder] = notification.useNotification();

  const draggerProps = {
    name: "file",
    beforeUpload(file) {
      readXlsxFile(file)
        // .then((data) => {
        //   if (data.length > 501) {
        //     setRowsLimitExceeded(true);
        //   }
        //   return data;
        // })
        .then(([cols, ...rows]) => {
          if (rows.length > 1) {
            setNRows(rows.length);
          }

          const mandatoryColumns = [
            "ORN",
            "DB",
            "PT",
            "AU",
            "JN",
            "VO",
            "IP",
            "PG",
            "TI",
            "AB",
          ];

          const valid = mandatoryColumns.every((mc) =>
            cols.some((c) => c === mc)
          );
          if (valid) {
            setFileName(file.name);
            setFile(file);
            setWorkStreamPopup(true);
          } else {
            api.error({
              message: "Error",
              description: "Failed to validate columns.",
            });
          }
          return valid;
        });
    },
    itemRender: () => <span />,
    onChange(info) {
      const { status } = info.file;

      if (status === "uploading") {
        // console.log(info.file, info.fileList);
      }
      // if (status === "done") {
      // message.success(`${info.file.name} file uploaded successfully.`);
      // console.log("done", { info });
      // setWorkStreamPopup(true);

      // } else if (status === "error") {
      //   message.error(`${info.file.name} file upload failed.`);
      // }
    },
    onDrop(e) {
      // console.log("Dropped files", e.dataTransfer.files);
    },
    customRequest(info) {
      // console.log(info.file);
      return true;
    },
  };

  const onClose = () => {
    setWorkStreamPopup(false);
    setNRows(0);
  };

  return (
    <div
      style={{
        padding: "110px 50px 0px",
      }}
    >
      {contextHolder}
      <Modal
        open={workStreamPopup}
        onOk={onClose}
        onCancel={onClose}
        title="Select workstream"
        footer={null}
        destroyOnClose={true}
      >
        <WorkStreams
          nRows={nRows}
          onClose={onClose}
          fileName={fileName}
          file={fileUpload}
          setAppKey={props.setAppKey}
          setCurrentProject={props.setCurrentProject}
        />
      </Modal>
      <h3
        style={{
          marginBottom: 30,
        }}
      >
        Create a project
      </h3>
      <Dragger
        {...draggerProps}
        style={{
          padding: "20px",
          margin: "20px auto 30px",
        }}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Click or drag file to this area to upload
        </p>
      </Dragger>

      <h3
        style={{
          marginBottom: 50,
        }}
      >
        Projects
      </h3>
      <ProjectsComponent setCurrentProject={props.setCurrentProject} isPage={true} />
    </div>
  );
};

const WorkStreams = (props) => {
  const [projectName, setProjectName] = useState("");
  const [workStream, setWorkStream] = useState("");
  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const history = useHistory();
  const [step, setStep] = useState(1);

  useEffect(() => {
    if (+props.nRows >= 500) {
      setStep(0);
    }
  }, []);

  const openNotification = () => {
    api.success({
      message: "Success",
      description: "Project " + projectName + " created successfully.",
    });
  };

  const save = () => {
    setLoading(true);
    createProject(props.fileName, projectName, workStream).then((res) => {
      const currentProject = res.data;
      uploadProjectFile(currentProject.id, props.file)
        .then((res) => {
          setLoading(false);
          openNotification();
          props.setCurrentProject(currentProject);
          setTimeout(() => {
            history.push("/screening/" + currentProject.id);
          }, 500);
        })
        .catch((e) => {
          setLoading(false);
          api.error({
            message: "Failed to create a new project.",
            description: e.response.data.detail,
          });
          console.log({ e });
        });
    });
  };

  return (
    <div>
      {contextHolder}
      {step === 0 ? (
        <>
          <Alert
            type="warning"
            message={
              <div>
                Your file contains {props.nRows} rows. The processing time is
                estimated to {(+props.nRows / 100) * 2 + 10} minutes. Do you
                wish to continue?
              </div>
            }
          />
          <div style={{ float: "right", marginTop: 20 }}>
            <Button
              style={{ marginRight: 10 }}
              type="primary"
              onClick={() => {
                setStep(1);
              }}
            >
              Yes
            </Button>

            <Button type="primary" onClick={props.onClose}>
              No
            </Button>
          </div>
        </>
      ) : (
        <>
          <Input
            onChange={(e) => setProjectName(e.target.value)}
            placeholder="Project Name"
          />
          <br />
          <br />
          <Select
            placeholder="Select Workstream"
            onChange={(value) => setWorkStream(value)}
            style={{ width: 470 }}
          >
            <Option value="Conceptual Development">
              Conceptual Development
            </Option>
            <Option value="COA Review" disabled>COA Review</Option>
          </Select>
          <br />
          <br />
          <div>
            <Button
              type="primary"
              onClick={save}
              style={{ marginRight: 20 }}
              loading={loading}
            >
              Save
            </Button>
            <Button type="primary" onClick={props.onClose}>
              Cancel
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
export default UploadPage;
