import { Button, Spin, Table } from "antd";

import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getProjects } from "../../helpers/apiCall";
import { Link } from "react-router-dom";

import {
  WarningTwoTone,
  QuestionCircleTwoTone,
  ClockCircleTwoTone,
  CheckCircleTwoTone,
} from "@ant-design/icons";

const columns = props => ([
  {
    title: "Name",
    dataIndex: "name",
  },
  {
    title: "Status",
    dataIndex: "ai_status",
    width: 150,
    render: (ai_status) => {
      switch (ai_status) {
        case "Not Started":
          return (
            <>
              <ClockCircleTwoTone
                twoToneColor="#aaa"
                style={{
                  fontSize: 20,
                  float: "left",
                  marginRight: 5,
                  marginTop: 1,
                }}
              />{" "}
              <span>{ai_status}</span>
            </>
          );
        case "In Progress":
          return (
            <>
              <Spin spinning={true} size="small" style={{ marginRight: 5 }} />{" "}
              <span>{ai_status}</span>
            </>
          );
        case "Failed":
          return (
            <>
              <WarningTwoTone
                twoToneColor="#ef5555"
                style={{
                  color: "red",
                  fontSize: 20,
                  float: "left",
                  marginRight: 5,
                  marginTop: 1,
                }}
              />
              <span>{ai_status}</span>
            </>
          );
        case "Success":
          return (
            <>
              <CheckCircleTwoTone
                twoToneColor="#28c653"
                style={{
                  fontSize: 20,
                  float: "left",
                  marginRight: 5,
                  marginTop: 1,
                }}
              />
              <span>{ai_status}</span>
            </>
          );
        default:
          return (
            <>
              <QuestionCircleTwoTone
                twoToneColor="#aaa"
                style={{
                  fontSize: 20,
                  float: "left",
                  marginRight: 5,
                  marginTop: 1,
                }}
              />
              <span>{ai_status}</span>
            </>
          );
      }
    },
  },

  {
    title: "Action",
    dataIndex: "",
    render: (e) => (
      <>
        <Button type="default" style={{ marginRight: 20 }}>
          <span onClick={()=> props.clickEvent('/screening/' + e.id, e)} >Screening</span>
        </Button>
        <Button type="default" disabled={e?.ai_status !== "Success"}>
          <span onClick={()=> props.clickEvent('/results/' + e.id, e)} >Results</span>
        </Button>
      </>
    ),
    width: 230,
  },
]);

function Projects(props) {
  const [projects, setProjects] = useState([]);
  const history = useHistory();
  
  const clickEvent = (to, p) => {
    props.setCurrentProject(p);
    history.push(to);
  };

  const cols = columns({clickEvent})

  useEffect(() => {
    getProjects().then((res) => {
      setProjects(res.data);
    });
  }, []);

  return (
    <div
      style={{
        padding: !props.isPage ? "150px 50px 0px" : undefined,
      }}
    >
      <Table
        dataSource={projects}
        columns={cols}
        bordered={true}
        pagination={{
          pageSize: 5,
        }}
      />
    </div>
  );
}

export default Projects;
